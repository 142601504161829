/* eslint-disable react/jsx-indent */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Page';
import SEO from '../components/SEO';

const Page = ({ data: { wordpressPage: { title, content }}}) => {
    return (
		<Layout>
		  <SEO title={title} />
		  <header className="page-header">
		    <div className="page-header__wrapper">
		      <h1 className="page-header__title">{title}</h1>
		    </div>
		  </header>
		  <div className="block block--xl-on-md">
		    <div className="wrapper wrapper--xs">
		      
		      <div className="template-content" dangerouslySetInnerHTML={{__html: content }} />
		    </div>
		  </div>
		</Layout>
		
    );
};

export default Page;

export const pageQuery = graphql`
	query PageByID($id: String!) {	
		wordpressPage(id: { eq: $id }) {
			title
			content
		}
	}
`;
